/**
 * This function will be triggered on the client to initiate the caas module. It requires a number of
 * exposed properties on the window.YAHOO and window.rapidPageConfig objects.
 * @return {Void} Void
 */

(function(window) {
    const {
        __cmp,
        CAAS,
        DARLA,
        rapidInstance,
        rapidPageConfig,
        wafer,
        YAHOO
    } = window;

    // YAHOO.comscore and YAHOO.context is exposed via the kaizen-context component
    const { comscore, context } = YAHOO;
    const { meta = {} } = context;
    const { c14 = '', enableTracking } = comscore;
    const {
        siteAttribute = '',
        xhrPathPrefix = '/tdv2_fp/remote',
        url = ''
    } = meta;

    // rapidPageConfig and SPACEID is exposed via the kaizen-rapid component
    const i13nEntities = rapidPageConfig.keys || {};
    const spaceid = YAHOO.i13n && YAHOO.i13n.SPACEID;

    const escPageUrl = encodeURI(url);
    const c1 = '2';
    const c2 = '7241469';
    let noScriptImgUrl = `https://sb.scorecardresearch.com/p?c1=${c1}&c2=${c2}&c7=${escPageUrl}&c5=${spaceid}&cv=2.0&cj=1`;
    let c14Markup = '';
    if (enableTracking) {
        noScriptImgUrl += `&c14=${c14}`;
        c14Markup = `, c14: '${c14}'`;
    }

    // beacon the initial comscore pageview
    _beaconComscore();

    // initialize caas js
    window.CAAS_ARTICLE2 = (() => {
        const caasInstances = {
            caasInstance: undefined
        };

        if (CAAS !== undefined) {
            caasInstances.caasInstance = new CAAS.BASE({
                container: 'module-article',
                i13nEntities
            });

            caasInstances.caasInstance.on('link:clicked', _linkClickHandler);

            caasInstances.caasInstance.on(
                'yvideo:mediacurrent',
                _playlistMediaCurrentHandler
            );

            caasInstances.caasInstance.on(
                'yvideo:interacted',
                _playlistInteractionHandler
            );

            caasInstances.caasInstance.on(
                'slideshow:slideChange',
                _slideChangeHandler
            );
        }
        return caasInstances;
    })();

    // if the article is opened with bcmt flag, collapse article and show read article button
    _bcmtHandler();

    // if sponsoredMoments ad is available, then replace the LREC placeholder from CAAS markup with sponsored-moments markup
    _sponsoredModuleHandler();

    /**
     * This is an util method to fire a comscore pageview beacon
     */
    function _beaconComscore() {
        const comscoreParams = {
            c1,
            c2,
            c5: spaceid,
            c7: `${url}${c14Markup}`
        };

        const beaconImg = new Image();
        let beaconUrl =
            'https://sb.scorecardresearch.com/p' +
            '?c7=' +
            encodeURIComponent(document.location.href) +
            '&ns__t=' +
            new Date().getTime();

        for (const key in comscoreParams) {
            if (comscoreParams.hasOwnProperty(key)) {
                beaconUrl +=
                    '&' + key + '=' + encodeURIComponent(comscoreParams[key]);
            }
        }

        if (__cmp) {
            __cmp('getVendorConsents', null, (consentData, success) => {
                if (
                    success &&
                    consentData &&
                    consentData.gdprApplies &&
                    consentData.vendorConsents
                ) {
                    beaconUrl +=
                        '&cs_ucfr=' + (consentData.vendorConsents[77] ? 1 : 0); // 77 is comscore's vendor id
                }
                beaconImg.src = beaconUrl;
            });
        } else {
            beaconImg.src = beaconUrl;
        }
    }

    /**
     * This is a handler method that listens to slide change event and fires the necessary beacons
     * and inserts the gemini ads by making wafer-fetch calls
     * @param {Object} data received from event listener
     */
    function _slideChangeHandler(data) {
        const slideshowPageParams = {
            pct: 'slideshow',
            pt: 'content'
        };

        if (rapidInstance) {
            rapidInstance.beaconPageview({
                ...i13nEntities,
                ...slideshowPageParams
            });
            _beaconComscore();
        }

        // Make wafer fetch calls to fill in the ads placeholders
        if (data.isAdSlide && data.adIndex >= 0) {
            const currentAdSlide = document.getElementsByClassName(
                'caas-ad-slide'
            )[data.adIndex];
            const isCurrentAdSlideAlreadyFilled =
                currentAdSlide.getElementsByClassName(
                    'gemini-ad-slide-img'
                )[0] ||
                currentAdSlide.getElementsByClassName(
                    'gemini-ad-slide-summary'
                )[0];

            if (!isCurrentAdSlideAlreadyFilled) {
                const adSlideImgNode = currentAdSlide.getElementsByClassName(
                    'caas-ad-img'
                )[0];
                const adSlideSummaryNode = currentAdSlide.getElementsByClassName(
                    'caas-ad-summary'
                )[0];

                // Gemini Dedupe is not needed for first ad
                const isGeminiDedupeEnabled = data.adIndex > 0;

                // construct gemini ad config to make a wafer fetch call on slideshow:change event
                const postBody = {
                    m_id: 'tdv2-wafer-content-list',
                    m_mode: 'json',
                    ctrl: 'AdsStrip',
                    config: {
                        headerConfig: {
                            title: ''
                        },
                        itemConfig: {
                            type: 'slideshow'
                        },
                        imageSizes: {
                            lite: 'size=original'
                        },
                        itemClasses: {
                            itemHeaderWrapperClasses: 'gemini-ad-slide-summary',
                            itemImgWrapperClasses: 'gemini-ad-slide-img'
                        },
                        itemWrapperClassNames: '',
                        ncpQueryParams: {
                            spaceId: spaceid,
                            count: 1,
                            snippetCount: 1,
                            ads_image_tag: '',
                            geminiDedupeToken: isGeminiDedupeEnabled
                                ? '[state.geminiAds.geminiDedupeToken]'
                                : ''
                        }
                    }
                };

                const postUrl = `${xhrPathPrefix}?m_id=tdv2-wafer-content-list&ctrl=AdsStrip&m_mode=json`;

                // use data-wf-state-body if geminidedupe token is read from wafer state
                const waferStateAttrs = isGeminiDedupeEnabled
                    ? 'data-wf-state-body'
                    : 'data-wf-body';

                // Make remote call to fetch the gemini Ad mark up to fill the slideshow placeholders
                adSlideImgNode.innerHTML = `
                    <div
                        id="gemini-ad-slide"
                        class="wafer-fetch wafer-fetch-container"
                        data-wf-trigger="onLoad"
                        data-wf-replace="1"
                        data-wf-url=${postUrl}
                        ${waferStateAttrs}=${JSON.stringify(postBody)}
                    >
                    </div>
                `;

                wafer.on('fetch:success', data => {
                    if (data.elem.id === 'gemini-ad-slide') {
                        try {
                            const script = adSlideImgNode.getElementsByTagName(
                                'script'
                            )[0];
                            adSlideSummaryNode.innerHTML = adSlideImgNode.getElementsByClassName(
                                'gemini-ad-slide-summary'
                            )[0].innerHTML;
                            const geminiImgNode = adSlideImgNode.getElementsByClassName(
                                'gemini-ad-slide-img'
                            )[0];
                            adSlideImgNode.parentNode.replaceChild(
                                geminiImgNode,
                                adSlideImgNode
                            );
                            currentAdSlide.appendChild(script);
                        } catch (e) {
                            // do nothing
                        }
                    }
                });

                if (wafer.base) {
                    // Sync wafer to know about the added wafer-fetch
                    wafer.base.sync(currentAdSlide);
                }
            }
        }

        // refresh lrec ad on slide change
        if (DARLA) {
            DARLA.add('slideshowadfetch', {
                name: 'slideshowadfetch',
                ps: 'LREC-2',
                sp: spaceid,
                sa: siteAttribute,
                ssl: true,
                npv: true
            });

            if (!DARLA.inProgress()) {
                DARLA.event('slideshowadfetch');
            }
        }
    }

    /**
     * This is a handler method that listens to link click event and fires the click beacons
     * @param {Object} data received from event listener
     */
    function _linkClickHandler(data) {
        const linkHref = data.href;
        const newWinCaas =
            (linkHref && window.open('', data.target || '_blank')) ||
            (linkHref && window);

        if (rapidInstance) {
            rapidInstance.beaconClick(
                data.sec,
                data.slk,
                data.pos || 0,
                data.params || {},
                data.outcm || null,
                () => {
                    newWinCaas && (newWinCaas.location.href = linkHref);
                }
            );
        } else {
            newWinCaas && (newWinCaas.location.href = linkHref);
        }
    }

    /**
     * This is a handler method that listens to mediacurrent(playlist item change) event and fires the
     * pageview beacon for the newly selected playlist item and saves the item in history
     * @param {Object} data received from event listener
     */
    function _playlistMediaCurrentHandler(data) {
        if (data) {
            const { id, title, provider } = data;
            const newVideoPageparams = {
                pstaid_p: id,
                pstaid: id
            };

            if (provider) {
                // @ts-ignore
                newVideoPageparams.pcp = provider.name;
            }

            // fire page view beacon when current item in the playlist is changed with updated i13n params
            if (rapidInstance) {
                rapidInstance.beaconPageview({
                    ...i13nEntities,
                    ...newVideoPageparams
                });
                _beaconComscore();
            }

            // update page title and push into history
            document.title = title;
            history.pushState(
                {
                    state: history.state
                },
                title
            );
        }
    }

    /**
     * This is a handler method that listens to video interacted event and fires the
     * click beacon when the interaction is a click(action: playlistItemClick)
     * @param {Object} data received from event listener
     */
    function _playlistInteractionHandler(data) {
        if (data) {
            const { action, index, title } = data;
            if (action === 'playlistItemClick' && rapidInstance) {
                rapidInstance.beaconClick('video', title, index, {}, null);
            }
        }
    }

    /**
     * This is an util method to handle body collapsing when bcmt flag is enabled.
     * if the article is opened with bcmt flag, add "caas-body-collapsed" class to caas body to
     * collapse the content and show read article button
     */
    function _bcmtHandler() {
        if (document && document.location.search.indexOf('bcmt') >= 1) {
            const bodyWrapper = [
                // @ts-ignore
                ...document.getElementsByClassName('caas-body-wrapper')
            ][0];
            bodyWrapper && bodyWrapper.classList.add('caas-body-collapsed');
        }
    }

    /**
     * This is an util method to handle sponsored moments placeholder in the body
     *  1) if sponsoredMoments ad is available(from tdv2 RMP call) and SM ad placeholder is available from caas body
     *      then fill the SM ad placeholder with the ad markup from the RMP call and change the LREC iframe id to
     *      "sda-LREC-iframe-not-rendered" inorder to avoid extra beacons for LREC.
     *  2) If SM ad is not received from RMP call or SM ad is disabled from from app, hide SM ad placeholder
     *      container in caas
     *  3) Finally remove the SM tdv2 module markup which is added in col1 region of the page by default
     */
    function _sponsoredModuleHandler() {
        const sponsoredModule = document.getElementById(
            'module-sponsoredMoments'
        );
        const smAdPlaceholders =
            document.getElementsByClassName('smad-container') || [];
        const smAdContainer = smAdPlaceholders[0];

        if (sponsoredModule && smAdContainer) {
            const smadMarkup = sponsoredModule.firstElementChild;
            if (smadMarkup && smadMarkup.innerHTML !== '') {
                smAdContainer.innerHTML = smadMarkup.innerHTML;
                smAdContainer.setAttribute('id', 'sponsoredMoments');
                const lrecAd = document.getElementById('sda-LREC');
                if (lrecAd) {
                    const lrecAdIframe = document.getElementById(
                        'sda-LREC-iframe'
                    );
                    lrecAdIframe.setAttribute(
                        'id',
                        'sda-LREC-iframe-not-rendered'
                    );
                    // Dont show LREC when SM is present
                    lrecAd.style.display = 'none';
                }
            } else {
                // if SM ad not received, hide SM ad container in caas if present
                smAdContainer.style.display = 'none';
            }
        } else if (smAdContainer) {
            // if SM is disabled from app, hide SM ad placeholder container in caas if present
            smAdContainer.style.display = 'none';
        }

        // remove SM tdv2 module which is added in col1 region of the page by default
        sponsoredModule &&
            sponsoredModule.parentNode &&
            sponsoredModule.parentNode.removeChild(sponsoredModule);
    }
})(window);
